import { CmaGrid, CmaTypography, Colors } from '@cma-cgm/cma-ui';
import { footerListEn, footerListFr } from './FooterConstants';

const Footer = (props: { language: string }) => {
    const { language } = props;
    const year = new Date().getFullYear();
    const footerList = language === 'en' ? footerListEn : footerListFr;

    return (
        <CmaGrid container display='flex' flexDirection='column' justifyContent='center' sx={{
            minHeight: '142.4px',
            width: '100%',
            padding: '0 20px !important',
            margin: '0 !important',
            backgroundColor: Colors.colorBackgroundDefault
        }}>
            <CmaGrid
                item
                display='flex'
                flexDirection={{ xs: 'column', lg: 'row' }}
                alignItems='center'
                justifyContent='center'
                sx={{
                    '@media (max-width: 600px)': {
                        margin: '10px 0 !important'
                    }
                }}>
                {footerList.map(({ id, title, url }) => (
                    <a
                        key={id}
                        href={url}
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            fontSize: '15.5px',
                            color: '#061d50',
                            whiteSpace: 'nowrap',
                            padding: '.7rem 1rem',
                            textDecoration: 'none',
                            margin: 0
                        }}>
                        {title}
                    </a>
                ))}
            </CmaGrid>

            <CmaGrid
                item
                display='flex'
                alignItems='center'
                justifyContent='center'
                sx={{
                    '@media (max-width: 600px)': {
                        margin: '10px 0 !important'
                    }
                }}>
                <CmaTypography sx={{ color: 'rgba(4,36,106,.5) !important' }}>
                    © {year} {language === 'en' ? 'CMA CGM Group' : 'CMA CGM Groupe'}
                </CmaTypography>
            </CmaGrid>
        </CmaGrid >
    );
};

export default Footer;