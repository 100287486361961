import { Outlet } from 'react-router-dom';
import HeaderCmpt from './Header';
import FooterCmpt from './Footer/Footer';
import { useSelector } from 'react-redux';
import { Dispatch, SetStateAction } from 'react';
import DisclaimerPage from '../../../modules/Disclaimer/DisclaimerPage';

const Layout = (props: { open: boolean, setOpen: Dispatch<SetStateAction<boolean>> }) => {
	const { open, setOpen } = props;
	const language = useSelector((state: any) => state?.language?.language);

	return (
		<>
			<HeaderCmpt language={language} />
			<DisclaimerPage open={open} setOpen={setOpen} />
			<Outlet />
			<FooterCmpt language={language} />
		</>
	);
};

export default Layout;