import { Typography } from '@mui/material';
import { CmaGrid, Colors } from '@cma-cgm/cma-ui';

const NotFoundPage = () => {

    return (
        <CmaGrid
            container
            item
            xs={12}
            display='flex'
            direction='column'
            alignItems='center'
            justifyContent='center'
            sx={{
                minHeight: 'calc(100vh - 92.41px)',
                backgroundColor: Colors.colorBackgroundDefault,
                padding: '107.41px 0 15px 0'
            }}>
            <CmaGrid item>
                <Typography sx={{
                    color: Colors.colorFillPrimaryBlue,
                    padding: '20px 5px',
                    fontSize: '80px !important'
                }}>404</Typography>
            </CmaGrid>
            <CmaGrid item>
                <Typography sx={{
                    color: Colors.colorFillPrimaryBlue,
                    padding: '30px 5px',
                    fontSize: '40px !important'
                }}>Page Not Found</Typography>
            </CmaGrid>
        </CmaGrid>
    );
};

export default NotFoundPage;