import { CmaGrid, Icon } from '@cma-cgm/cma-ui';
import { MainPage, Text } from './HomePageStyles';
import { useEffect } from 'react';
import { homeList } from './HomeConstants';
import { IconButton } from '@mui/material';
import { useSelector } from 'react-redux';

const HomePage = (props: { open: boolean }) => {
    const { open } = props;
    const language = useSelector((state: any) => state?.language?.language);

    const handleDownload = (fileUrl: any) => {
        window.open(fileUrl, '_blank');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {open ?
                <MainPage container justifyContent='center'>
                    <Text variant='h1' textAlign='center'>
                        You need to Accept the Rules & Regulations to view the content.
                    </Text>
                </MainPage> :
                <MainPage
                    container
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    sx={{ paddingBottom: '20px' }}
                    gap={5}>
                    <Text variant='h1' textAlign='center'>
                        {(language === 'en') ? 'Possible acquisition of Wincanton plc' : 'Possible acquisition de Wincanton plc'}
                    </Text>

                    {homeList.map((listItem: any, index: number) => (
                        <CmaGrid key={index} item container display='flex' flexDirection='column' width='90%'>
                            <CmaGrid item>
                                <Text variant='h4'>
                                    {listItem.title}:
                                </Text>
                            </CmaGrid>
                            {listItem.sections.map((section: any, index: number) => (
                                <CmaGrid key={index} item display='flex' alignItems='center'>
                                    <Text variant='body1'>
                                        <span style={{ fontWeight: 700 }}>{section.date}</span>: {section.title}
                                    </Text>
                                    <IconButton onClick={() => handleDownload(section.file)}>
                                        <Icon
                                            color='default'
                                            name='save'
                                            size='small'
                                        />
                                    </IconButton>

                                </CmaGrid>
                            ))}
                        </CmaGrid>
                    ))}
                </MainPage>}
        </>
    );
};

export default HomePage;