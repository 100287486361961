export const topHeaderListEn = [
	{
		id: 1,
		title: 'Finance',
		url: 'https://cmacgm-group.com/en/finance',
		icon: '/icons/user.svg'
	},
	{
		id: 2,
		title: 'Subsidiaries',
		url: 'https://cmacgm-group.com/en/subsidiaries'
	},
	{
		id: 3,
		title: 'Contact Us',
		url: 'https://cmacgm-group.com/en/contact'
	},
	{
		id: 4,
		title: 'Go to our business website',
		url: 'https://www.cma-cgm.com/'
	}
];

export const topHeaderListFr = [
	{
		id: 1,
		title: 'Finance',
		url: 'https://cmacgm-group.com/fr/finance',
		icon: '/icons/user.svg'
	},
	{
		id: 2,
		title: 'Filiales',
		url: 'https://cmacgm-group.com/fr/subsidiaries'
	},
	{
		id: 3,
		title: 'Contact',
		url: 'https://cmacgm-group.com/fr/contact'
	},
	{
		id: 4,
		title: 'Aller sur notre site business',
		url: 'https://www.cma-cgm.fr/'
	}
];

export const headerListEn = [
	{
		id: 1,
		title: 'The CMA CGM Group',
		url: 'https://cmacgm-group.com/en/group-and-vision'
	},
	{
		id: 2,
		title: 'Sustainability',
		url: 'https://cmacgm-group.com/en/sustainability-and-innovation'
	},
	{
		id: 3,
		title: 'PULSE',
		url: 'https://cmacgm-group.com/en/fund-for-energies'
	},
	{
		id: 4,
		title: 'Innovation',
		url: 'https://cmacgm-group.com/en/innovation-in-shipping-and-logistics'
	},
	{
		id: 5,
		title: 'Careers',
		url: 'https://cmacgm-group.com/en/talents'
	},
	{
		id: 6,
		title: 'News & Media',
		url: 'https://cmacgm-group.com/en/news-media'
	},
	{
		id: 7,
		title: 'The CMA CGM Foundation',
		url: 'https://cmacgm-group.com/en/foundation'
	}
];

export const headerListFr = [
	{
		id: 1,
		title: 'Le Groupe CMA CGM',
		url: 'https://cmacgm-group.com/fr/groupe-et-vision'
	},
	{
		id: 2,
		title: 'Sustainability',
		url: 'https://cmacgm-group.com/fr/developpement-durable-et-innovation'
	},
	{
		id: 3,
		title: 'PULSE',
		url: 'https://cmacgm-group.com/fr/fonds-energies'
	},
	{
		id: 4,
		title: 'Innovation',
		url: 'https://cmacgm-group.com/fr/innover-dans-le-transport-maritime-et-la-logistique'
	},
	{
		id: 5,
		title: 'Carrières',
		url: 'https://cmacgm-group.com/fr/talents'
	},
	{
		id: 6,
		title: 'Actualités & Média',
		url: 'https://cmacgm-group.com/fr/actualites-media'
	},
	{
		id: 7,
		title: 'Fondation CMA CGM',
		url: 'https://cmacgm-group.com/fr/fondation'
	}
];