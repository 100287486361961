import { CmaDivider, CmaGrid, CmaTypography, Colors } from '@cma-cgm/cma-ui';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Box, Modal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { disclaimerList } from './DisclaimerConstants';
import './Button.scss';

const DisclaimerPage = (props: { open: boolean, setOpen: Dispatch<SetStateAction<boolean>> }) => {
    const { open, setOpen } = props;
    const handleClose = () => setOpen(false);

    /*
    /d -> starts with a dash
    /w -> bold & uppercase
    */

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Modal
            open={open}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{ backdrop: { timeout: 500 } as any }}>
            <Box sx={{
                position: 'fixed',
                top: 'calc(50% + 25px)',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '95%',
                height: 'calc(100% - 50px)',
                backgroundColor: Colors.colorBackgroundDefault,
                border: 'none',
                borderRadius: '5px',
                outline: 'none',
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: '#04246a #f5f5f5',
                '&::-webkit-scrollbar': {
                    width: '9px !important',
                    height: '8px !important'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#04246a',
                    borderRadius: '4px'
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#f5f5f5',
                    borderRadius: '4px'
                }
            }}>
                <CmaGrid container gap={3} display='flex' justifyContent='center'>
                    <CmaGrid
                        item
                        container
                        xs={9}
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        sx={{
                            position: 'sticky', top: '0', left: '0', backgroundColor: 'white'
                        }}>
                        <CmaTypography variant='h3' sx={{
                            textTransform: 'uppercase',
                            color: `${Colors.colorFillPrimaryBlue} !important`,
                            marginBottom: '10px !important'
                        }} marginY={5}>
                            Disclaimer
                        </CmaTypography>
                        <CmaDivider sx={{
                            backgroundColor: `${Colors.colorFillPrimaryRed} !important`,
                            margin: '0 !important'
                        }} />
                    </CmaGrid>
                    <CmaGrid item container xs={9} gap={3}>
                        {disclaimerList.map((disclaimerItem) => (
                            <CmaGrid key={disclaimerItem.id} item container gap={2}>
                                <CmaTypography variant='h3' sx={{
                                    color: `${Colors.colorFillPrimaryBlue} !important`,
                                    marginBottom: '10px !important'
                                }}>
                                    {disclaimerItem.title}
                                </CmaTypography>

                                <CmaGrid item display='flex' flexDirection='column' gap={2}>
                                    {disclaimerItem.text.split('/q').map((line, index) => (
                                        <CmaTypography key={index} variant='body1'
                                            sx={line.startsWith('/w') ?
                                                { fontWeight: 'bold', textTransform: 'uppercase', color: `${Colors.colorFillPrimaryBlue} !important` } : { color: `${Colors.colorFillPrimaryBlue} !important` }}>
                                            {line.startsWith('/d') && <span style={{
                                                color: `${Colors.colorFillPrimaryRed} !important`
                                            }}>—&nbsp;</span>}
                                            {(line.startsWith('/w') || line.startsWith('/d')) ?
                                                line.substring(2) : line}
                                        </CmaTypography>
                                    ))}
                                </CmaGrid>
                            </CmaGrid>
                        ))}
                    </CmaGrid>
                    <CmaGrid item container xs={9} display='flex' alignItems='center' gap={3}>
                        <a onClick={handleClose} className='accept-button'>Accept</a>

                        <a target='_blank'
                            rel='noopener noreferrer'
                            href='https://cmacgm-group.com'
                            className='style-button'>
                            <span>Go to Corporate Website</span>
                        </a>
                    </CmaGrid>
                </CmaGrid>
            </Box>
        </Modal>
    );
};

export default DisclaimerPage;