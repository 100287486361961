import { Colors } from '@cma-cgm/cma-ui';
import styled from '@emotion/styled';
import { CmaGrid, CmaTypography, Icon } from '@cma-cgm/cma-ui';

export const MainPage = styled(CmaGrid)({
    minHeight: 'calc(100vh - 142.41px)',
    backgroundColor: '#f3f5f7',
    padding: '140.41px 0 15px 0'
});

export const Text = styled(CmaTypography)({
    color: `${Colors.colorFillPrimaryBlue} !important`
});