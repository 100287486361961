import React, { useState } from 'react';
import './App.css';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import Layout from './shared/components/layout/Layout';
import HomePage from './modules/Home/HomePage';
import { FontStylesGlobal } from '@cma-cgm/cma-ui';
import NotFoundPage from './shared/components/NotFoundPage/NotFoundPage';

function App() {
	const [open, setOpen] = useState(true);

	const router = createBrowserRouter([
		{
			element: <Layout open={open} setOpen={setOpen} />,
			children: [
				{
					path: '/',
					element: <Navigate to='home' />
				},
				{
					path: 'home',
					element: <HomePage open={open} />
				},
				{
					path: '*',
					element: <NotFoundPage />
				}
			]
		}
	]);

	return (
		<div className="App">
			<FontStylesGlobal />
			<RouterProvider router={router} />
		</div>
	);
}

export default App;