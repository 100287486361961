import styled from '@emotion/styled';
import { CmaTypography } from '@cma-cgm/cma-ui';
import { Colors, Constants } from '@cma-cgm/cma-ui';

export const Text = styled(CmaTypography)({
    color: `${Colors.colorFillPrimaryBlue} !important`,
    borderLeft: `2px solid ${Colors.colorFillPrimaryRed}`,
    cursor: 'pointer',
    margin: '10px 5px',
    padding: '10px 35px 10px 15px',
    '& span': {
        fontFamily: Constants.fontSecondaryMedium
    }
});

export const Links = styled('a')({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '15.5px',
    textDecoration: 'none',
    color: '#061d50',
    whiteSpace: 'nowrap',
    padding: '0 0.52rem',
    margin: 0,
    '&:hover': {
        color: Colors.colorFillPrimaryRed
    }
});