import Popover from '@mui/material/Popover';
import { Links, Text } from './PopoverStyles';
import { useState } from 'react';
import Grow from '@mui/material/Grow';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../../features/language/languageSlice';

const MouseOverPopover = () => {
    const dispatch = useDispatch();

    const language = useSelector((state: any) => state?.language?.language);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [popoverSize, setPopoverSize] = useState({ width: 500, height: 150 });

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleTranslation = (language: string) => {
        dispatch(setLanguage(language));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleExited = () => {
        const popoverElement = document.getElementById('mouse-over-popover');
        if (popoverElement) {
            const { width, height } = popoverElement.getBoundingClientRect();
            setPopoverSize({ width, height });
        }
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Links>
                <span onClick={handleClick}>
                    {language.toUpperCase()} <span style={{ fontSize: '16px' }}>&#x25BE;</span>
                </span>
            </Links>
            <Popover
                id='mouse-over-popover'
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                TransitionComponent={Grow}
                TransitionProps={{ onExited: handleExited }}
                style={{ width: popoverSize.width, height: popoverSize.height }}
                disableRestoreFocus>
                <Text onClick={() => handleTranslation('en')}>English</Text>
                <Text onClick={() => handleTranslation('fr')}>Français</Text>
            </Popover>
        </>
    );
};

export default MouseOverPopover;