import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { headerListEn, topHeaderListEn, headerListFr, topHeaderListFr } from './HeaderConstants';
import { useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import { AccordionSummary } from '@mui/material';
import MouseOverPopover from '../../Popover/Popover';
import { Colors, Constants } from '@cma-cgm/cma-ui';

function Header(props: { language: string }) {
	const { language } = props;

	const [hovered, setHovered] = useState<boolean>(false);
	const [mobileOpen, setMobileOpen] = useState<boolean>(false);

	const headerList = language === 'en' ? headerListEn : headerListFr;
	const topHeaderList = language === 'en' ? topHeaderListEn : topHeaderListFr;

	const navigate = useNavigate();
	console.error = () => { return <></>; };

	const handleToggle = () => {
		setMobileOpen((prevState: boolean) => !prevState);
	};

	return (
		<>
			<AppBar component='nav' sx={{ backgroundColor: 'white', boxShadow: '0 3px 5px rgba(0,0,0,0.2)' }}>
				{/* Top Nav */}
				<Toolbar sx={{
					minHeight: '2.2rem !important',
					maxHeight: '2.2rem !important',
					display: { xs: 'none', md: 'inherit' },
					alignItems: 'center',
					justifyContent: 'flex-end',
					padding: '0 50px 0 1rem',
					borderBottom: '1px solid #f3f5f7',
					backgroundColor: '#fafbfc',
					zIndex: 1,
					'& .MuiToolbar-root': {
						minHeight: '2.2rem !important',
						maxHeight: '2.2rem !important'
					}
				}}>
					<Box
						sx={{
							display: { xs: 'none', md: 'flex' },
							justifyContent: 'space-between',
							flex: 0.43,
							padding: '0 20px'
						}}>
						{topHeaderList.map(({ id, title, url, icon }) => (
							<a
								key={id}
								target='_blank'
								style={{
									display: 'flex',
									alignItems: 'center',
									cursor: 'pointer',
									fontSize: '15.5px',
									textDecoration: 'none',
									color: '#061d50',
									whiteSpace: 'nowrap',
									padding: '0 0.52rem',
									margin: 0
								}}
								href={url}
								rel='noopener noreferrer'>
								{icon && <img style={{
									margin: '2px 6px 0 0',
									width: '12.8px',
									height: '12.8px'
								}} src={icon} />}
								{title}
							</a>
						))}
						<MouseOverPopover />
					</Box>
				</Toolbar>
				{/* Bottom Nav */}
				<Toolbar
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'space-between',
						padding: '9px 59px 9px 21px !important'

					}}>
					<IconButton onClick={handleToggle} sx={{ display: { xs: 'flex', md: 'none' } }}>
						<MenuIcon />
					</IconButton>

					<img
						src='/cma-logo.svg'
						style={{
							display: mobileOpen ? 'none' : 'flex',
							width: '100%',
							cursor: 'pointer',
							maxWidth: '200px',
							maxHeight: '3.8125rem',
							marginTop: '0.25rem'
						}}
						onClick={() => {
							navigate('/');
						}}
					/>

					{/* For Desktop */}
					<Box
						sx={{
							display: { xs: 'none', md: 'flex' },
							justifyContent: { lg: 'space-between' },
							flexWrap: 'wrap',
							flex: 0.906,
							'@media (min-width: 1600px)': {
								flex: 0.7
							}
						}}>
						{headerList.map(({ id, title, url }) => (
							<a
								key={id}
								target='_blank'
								href={url}
								rel='noopener noreferrer'
								onMouseEnter={() => { setHovered(true); }}
								onMouseLeave={() => { setHovered(false); }}
								style={{
									cursor: 'pointer',
									color: '#061d50',
									padding: '0 1rem 0 2rem',
									whiteSpace: 'nowrap',
									textDecoration: 'none',
									borderLeft: `2px solid ${Colors.colorFillPrimaryRed}`,
									fontFamily: Constants.fontSecondaryMedium,
									fontSize: Constants.fontSizeM

								}}>
								{/* className={hovered ? clsx(classes.headerLinks, classes.hoveredList) : classes.headerLinks}> */}
								{title}
							</a>
						))}
					</Box>
				</Toolbar>

				{/* For Mobile */}
				<Box component='nav'>
					<Drawer
						variant='temporary'
						open={mobileOpen}
						onClose={handleToggle}
						sx={{
							'& .MuiDrawer-paper': { width: '350px' }
						}}>
						<Box sx={{ textAlign: 'center' }}>
							<img src='/cma-logo.svg' style={{
								width: '40%',
								padding: '25px'
							}} />

							<Divider />
							{headerList.map((listItem) => (
								<Accordion
									key={listItem.id}
									onChange={() => { setMobileOpen(false); }}>
									<AccordionSummary>
										<a
											target='_blank'
											rel='noopener noreferrer'
											href={listItem.url}
											style={{
												fontSize: 'calc(20px / 1.2) !important',
												fontWeight: 400,
												textDecoration: 'none',
												color: '#04246a !important',
												paddingRight: '20px'
											}}>
											{listItem.title}
										</a>
									</AccordionSummary>
								</Accordion>
							))}
						</Box>
					</Drawer>
				</Box>
			</AppBar>
		</>
	);
}
export default Header;