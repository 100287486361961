import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import languageReducer from './features/language/languageSlice';

export default configureStore({
	reducer: {
		language: languageReducer
	},
	middleware: getDefaultMiddleware({
		serializableCheck: false
	})
});