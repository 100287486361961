export const homeList = [
    {
        id: 1,
        title: 'ANNOUNCEMENTS',
        sections: [
            {
                id: 11,
                date: '19/01/24',
                title: 'Rule 2.7 Announcement',
                file: '/files/Project Tudor - 2.7 (Final for RNS).pdf'
            },
            {
                id: 12,
                date: '27/02/2024',
                title: 'Announcement of GFSC condition fulfilment',
                file: '/files/Announcement_of_GFSC_Approval.docx'
            }
        ]
    },
    {
        id: 2,
        title: 'OFFER RELATED ARRANGEMENTS',
        sections: [
            {
                id: 21,
                date: '19/01/24',
                title: 'Co-operation Agreement',
                file: '/files/Cooperation Agreement.pdf'
            },
            {
                id: 22,
                date: '13/12/23',
                title: 'Confidentiality Agreement',
                file: '/files/Confidentiality Agreement.pdf'
            }
        ]
    },
    {
        id: 3,
        title: 'IRREVOCABLE UNDERTAKINGS',
        sections: [
            {
                id: 31,
                date: '19/01/24',
                title: 'Director Irrevocable Undertaking - Anthony Bickerstaff',
                file: '/files/Director Irrevocable Undertaking (Anthony Bickerstaff).pdf'
            },
            {
                id: 32,
                date: '19/01/24',
                title: 'Director Irrevocable Undertaking - Debbie Lentz',
                file: '/files/Director Irrevocable Undertaking (Debbie Lentz).pdf'
            },
            {
                id: 33,
                date: '19/01/24',
                title: 'Director Irrevocable Undertaking - Gill Barr',
                file: '/files/Director Irrevocable Undertaking (Gill Barr).pdf'
            },
            {
                id: 34,
                date: '19/01/24',
                title: 'Director Irrevocable Undertaking - James Wroath',
                file: '/files/Director Irrevocable Undertaking (James Wroath).pdf'
            },
            {
                id: 35,
                date: '19/01/24',
                title: 'Director Irrevocable Undertaking - John Pattullo',
                file: '/files/Director Irrevocable Undertaking (John Pattullo).pdf'
            },
            {
                id: 36,
                date: '19/01/24',
                title: 'Director Irrevocable Undertaking - Mihiri Jayaweera',
                file: '/files/Director Irrevocable Undertaking (Mihiri Jayaweera).pdf'
            },
            {
                id: 37,
                date: '19/01/24',
                title: 'Director Irrevocable Undertaking - Sir Martin Reed CBE',
                file: '/files/Director Irrevocable Undertaking (Sir Martin Read CBE).pdf'
            },
            {
                id: 38,
                date: '19/01/24',
                title: 'Director Irrevocable Undertaking - Tom Hinton',
                file: '/files/Director Irrevocable Undertaking (Tom Hinton).pdf'
            },
            {
                id: 39,
                date: '18/01/24',
                title: 'Shareholder Letter of Intent - Schroders Investment Management Limited',
                file: '/files/Letter of Intent - Schroders.pdf'
            },
            {
                id: 30,
                date: '18/01/24',
                title: 'Shareholder Letter of Intent - Polar Capital LLP',
                file: '/files/Letter of Intent - Polar Capital.pdf'
            },
            {
                id: 52,
                date: '24/01/2024',
                title: 'Letter of intent - Schroders disposal',
                file: '/files/2.10 Announcement (Schroders - disposal).pdf'
            },
            {
                id: 53,
                date: '29/01/2024',
                title: '2.10 (a) Announcement (Threadneedle)',
                file: '/files/2.10(a) Announcement (Threadneedle).pdf'
            },
            {
                id: 54,
                date: '29/01/2024',
                title: 'Letter of intent - Threadneedle',
                file: '/files/Threadneedle LOI - 26 Jan.pdf'
            },
            {
                id: 55,
                date: '30/01/2024',
                title: '2.10 Announcement - Threadneedle disposal',
                file: '/files/2.10 Announcement (Threadneedle - disposal) 30.01.24.pdf'
            },
            {
                id: 56,
                date: '05/02/2024',
                title: '2.10 Announcement - Schroders disposal',
                file: '/files/Project Tudor - 2.10 Announcement (Schroders - disposal).pdf'
            },
            {
                id: 57,
                date: '07/02/2024',
                title: '2.10 Announcement - Threadneedle disposal',
                file: '/files/2.10 Announcement (Threadneedle - disposal) 07.02.24.pdf'
            },
            {
                id: 58,
                date: '15/02/2024',
                title: '2.10 Announcement - Schroders disposal',
                file: '/files/2.10 Announcement (Schroders - disposal) 15.02.24.pdf'
            },
            {
                id: 59,
                date: '15/02/2024',
                title: '2.10 Announcement - Schroders second disposal',
                file: '/files/2.10 Announcement (Schroders - disposal) 15.02.24 (second disposal).pdf'
            },
            {
                id: 60,
                date: '19/02/2024',
                title: '2.10 Announcement - Schroders disposal',
                file: '/files/2.10 Announcement (Schroders - disposal) 16.02.24.pdf'
            },
            {
                id: 61,
                date: '20/02/2024',
                title: '2.10 Announcement - Schroders LOI correction',
                file: '/files/2.10 Announcement (Schroders) 20.02.24.pdf'
            },
            {
                id: 62,
                date: '22/02/2024',
                title: '2.10 Announcement - Schroders disposal',
                file: '/files/2.10 Announcement (Schroders - disposal) 22.02.24.pdf'
            },
            {
                id: 63,
                date: '23/02/2024',
                title: 'Irrevocable – Aberforth',
                file: '/files/Tudor - Aberforth Irrevocable - 23 Feb 2024 [redacted].pdf'
            },
            {
                id: 64,
                date: '25/02/2024',
                title: 'Irrevocable – Wellcome Trust',
                file: '/files/Tudor - Wellcome Trust Irrevocable - 25 Feb 2024 [redacted].pdf'
            }
        ]
    },
    {
        id: 4,
        title: 'CONSENT LETTERS',
        sections: [
            {
                id: 41,
                date: '18/01/24',
                title: 'Consent Letter for Rule 2.7 Announcement - Morgan Stanley',
                file: '/files/Consent Letter - Morgan Stanley (Rule 2.7 Announcement) (2).pdf'
            },
            {
                id: 42,
                date: '19/01/24',
                title: 'Consent Letter for Rule 2.7 Announcement - HSBC',
                file: '/files/Consent Letter - HSBC (Rule 2.7 Announcement).pdf'
            },
            {
                id: 43,
                date: '19/01/24',
                title: 'Consent Letter for Rule 2.7 Announcement - Deutsche Numis',
                file: '/files/Consent Letter - Deutsche Numis (Rule 2.7 Announcement).pdf'
            }
        ]
    },
    {
        id: 5,
        title: 'FINANCIAL INFORMATION',
        sections: [
            {
                id: 51,
                date: '19/01/24',
                title: 'CMA CGM consolidated accounts FY22',
                file: '/files/CMA CGM FY22 Group Accounts.pdf'
            }
        ]
    },
    {
        id: 6,
        title: 'RULE 2.11 COMMUNICATIONS FOLLOWING 2.7 ANNOUNCEMENT',
        sections: [
            {
                id: 61,
                date: '19/01/24',
                title: 'Rule 2.11 Communication - letter to shareholders (post - hard copies)',
                file: '/files/2.11 Letter to Shareholders (post - hard copies) - 19 January 2024.pdf'
            },
            {
                id: 62,
                date: '19/01/24',
                title: 'Rule 2.11 Communication - 2.11 letter to shareholders (post - directing to microsite)',
                file: '/files/2.11 Letter to Shareholders (post - directing to microsite) - 19 January 2024.pdf'
            },
            {
                id: 63,
                date: '19/01/24',
                title: 'Rule 2.11 Communication - 2.11 letter to shareholders (email - directing to microsite)',
                file: '/files/2.11 Letter to Shareholders (email - directing to microsite) - 19 January 2024.pdf'
            },
            {
                id: 64,
                date: '19/01/24',
                title: 'Rule 2.11 Communication - 2.11 letter to employees and employee representatives (directing to microsite)',
                file: '/files/2.11 Letter to Employees and Employee Representatives (directing to microsite) - 19 January 2024.pdf'
            },
            {
                id: 65,
                date: '19/01/24',
                title: 'Rule 2.11 Communication - 2.11 letter to information rights holders (post - hard copies)',
                file: '/files/2.11 Letter to Information Rights Holders (post - hard copies) - 19 January 2024.pdf'
            },
            {
                id: 66,
                date: '19/01/24',
                title: 'Rule 2.11 Communication - 2.11 letter to information rights holders (email - directing to microsite)',
                file: '/files/2.11 Letter to Information Rights Holders (email - directing to microsite) - 19 January 2024.pdf'
            },
            {
                id: 67,
                date: '19/01/24',
                title: 'Rule 2.11 Communication - 2.11 letter to optionholders (post - directing to microsite)',
                file: '/files/2.11 Letter to Optionholders (post - directing to microsite) - 19 January 2024.pdf'
            },
            {
                id: 68,
                date: '19/01/24',
                title: 'Rule 2.11 Communication - 2.11 letter to pension scheme trustees (directing to microsite)',
                file: '/files/2.11 Letter to Pension Scheme Trustees (directing to microsite) - 19 January 2024.pdf'
            }
        ]
    },
    {
        id: 7,
        title: 'Scheme Documents',
        sections: [
            {
                id: 71,
                date: '15/02/2024',
                title: 'Scheme Circular',
                file: '/files/Scheme Circular.pdf'
            },
            {
                id: 72,
                date: '15/02/2024',
                title: 'Announcement of Publication of Scheme Circular',
                file: '/files/Announcement of Publication of Scheme Circular.pdf'
            },
            {
                id: 73,
                date: '15/02/2024',
                title: 'Form of Proxy (Scheme Meeting)',
                file: '/files/Form of Proxy (Scheme Meeting).pdf'
            },
            {
                id: 74,
                date: '15/02/2024',
                title: 'Form of Proxy (General Meeting)',
                file: '/files/Form of Proxy (General Meeting).pdf'
            },
            {
                id: 75,
                date: '15/02/2024',
                title: 'Wincanton Annual Report and Accounts 2022',
                file: '/files/Wincanton Annual Report and Accounts 2022.pdf'
            },
            {
                id: 76,
                date: '15/02/2024',
                title: 'Wincanton Annual Report and Accounts 2023',
                file: '/files/Wincanton Annual Report and Accounts 2023.pdf'
            },
            {
                id: 77,
                date: '15/02/2024',
                title: 'Wincanton Q1 Trading Update',
                file: '/files/Wincanton Q1 Trading Update.pdf'
            },
            {
                id: 78,
                date: '15/02/2024',
                title: 'Wincanton Half Year Results for the six months to 30 September 2023',
                file: '/files/Wincanton Half Year Results for the six months to 30 September 2023.pdf'
            },
            {
                id: 79,
                date: '15/02/2024',
                title: 'Wincanton Q3 Trading Update',
                file: '/files/Wincanton Q3 Trading Update.pdf'
            },
            {
                id: 80,
                date: '15/02/2024',
                title: 'Consent Letter - Morgan Stanley (Scheme Document)',
                file: '/files/Consent Letter - Morgan Stanley (Scheme Document).pdf'
            },
            {
                id: 81,
                date: '15/02/2024',
                title: 'Consent Letter - HSBC (Scheme Document)',
                file: '/files/Consent Letter - HSBC (Scheme Document).pdf'
            },
            {
                id: 82,
                date: '15/02/2024',
                title: 'Consent Letter - Deutsche Numis (Scheme Document)',
                file: '/files/Consent Letter - Deutsche Numis (Scheme Document).pdf'
            },
            {
                id: 83,
                date: '15/02/2024',
                title: 'Consent Letter - UBS (Scheme Document)',
                file: '/files/Consent Letter - UBS (Scheme Document).pdf'
            },
            {
                id: 84,
                date: '15/02/2024',
                title: 'Current Wincanton Articles of Association',
                file: '/files/Current Wincanton Articles of Association.pdf'
            },
            {
                id: 85,
                date: '15/02/2024',
                title: 'Proposed Amended Wincanton Articles of Association',
                file: '/files/Proposed Amended Wincanton Articles of Association.pdf'
            },
            {
                id: 86,
                date: '15/02/2024',
                title: 'Current Bidco Articles of Association',
                file: '/files/Current Bidco Articles of Association.pdf'
            },
            {
                id: 87,
                date: '15/02/2024',
                title: 'Rule 24 Employee and Employee Representatives Notification',
                file: '/files/Rule 24 Employee and Employee Representatives Notification.pdf'
            },
            {
                id: 88,
                date: '15/02/2024',
                title: 'Rule 24 Pension Scheme Trustee Notification',
                file: '/files/Rule 24 Pension Scheme Trustee Notification.pdf'
            },
            {
                id: 89,
                date: '15/02/2024',
                title: 'Rule 15 Letter (LTIP)',
                file: '/files/Rule 15 Letter (LTIP).pdf'
            },
            {
                id: 90,
                date: '15/02/2024',
                title: 'Employee Letter (LTIP)',
                file: '/files/Employee Letter (LTIP).pdf'
            },
            {
                id: 91,
                date: '15/02/2024',
                title: 'Employee Letter (DSBP)',
                file: '/files/Employee Letter (DSBP).pdf'
            },
            {
                id: 92,
                date: '15/02/2024',
                title: 'Consent Letter - HSBC (Rule 15 Letter)',
                file: '/files/Consent Letter – HSBC (Rule 15 Letter).pdf'
            }
        ]
    },
    {
        id: 7,
        title: 'Supplementary Scheme Announcement',
        sections: [ 
            {
                id: 93,
                date: '26/02/2024',
                title: 'Consent Letter – Morgan Stanley (Supplementary Scheme Announcement)',
                file: '/files/MS_Consent_Letter_Supplementary_Scheme_Announcement.pdf'
            },
            {
                id: 94,
                date: '26/02/2024',
                title: 'Consent Letter – Deutsche Numis (Supplementary Scheme Announcement)',
                file: '/files/Rum_DN_Consent_Letter_Supplemental_Announcement.pdf'
            },
            {
                id: 95,
                date: '26/02/2024',
                title: 'Consent Letter - UBS (Supplementary Scheme Announcement)',
                file: '/files/R202402_UBS_Increased_Offer_Consent_Letter_RUM_signed.pdf'
            },
            {
                id: 96,
                date: '26/02/2024',
                title: 'Consent Letter - HSBC (Supplementary Scheme Announcement)',
                file: '/files/Wincanton_HSBC_Consent_Letter_Supplementary_Scheme_Announcement_executed.pdf'
            },
            {
                id: 97,
                date: '26/02/2024',
                title: 'Supplementary Scheme Announcement – Increased and Final Offer Price',
                file: '/files/Increased_and_Final_Offer_Price.pdf'
            }
        ]
    }
];