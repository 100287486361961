export const footerListEn = [
	{
		id: 1,
		title: 'Contact',
		url: 'https://cmacgm-group.com/en/contact'
	},
	{
		id: 2,
		title: 'Sitemap',
		url: 'https://cmacgm-group.com/en/sitemap'
	},
	{
		id: 3,
		title: 'Privacy notice',
		url: 'https://www.cma-cgm.com/legal-terms/privacy-policy'
	},
	{
		id: 4,
		title: 'Legal terms',
		url: 'https://cmacgm-group.com/en/legal-terms'
	},
	{
		id: 5,
		title: 'Responsible Disclosure',
		url: 'https://app.zerocopter.com/en/cvd/562e618b-b5b0-419d-9bde-1def84232f2e'
	},
	{
		id: 6,
		title: 'Cookie Settings',
		url: 'https://cmacgm-group.com/en/legal-terms'
	}
];

export const footerListFr = [
	{
		id: 1,
		title: 'Contact',
		url: 'https://cmacgm-group.com/fr/contact'
	},
	{
		id: 2,
		title: 'Plan du site',
		url: 'https://cmacgm-group.com/fr/plan-site'
	},
	{
		id: 3,
		title: 'Avis de confidentialité',
		url: 'https://www.cma-cgm.fr/legal-terms/privacy-Notice'
	},
	{
		id: 4,
		title: 'Mentions légales',
		url: 'https://cmacgm-group.com/fr/mentions-legales'
	},
	{
		id: 5,
		title: 'Divulgation Responsable',
		url: 'https://app.zerocopter.com/fr/cvd/562e618b-b5b0-419d-9bde-1def84232f2e'
	},
	{
		id: 6,
		title: 'Paramètres des cookies',
		url: 'https://cmacgm-group.com/fr/mentions-legales'
	}
];